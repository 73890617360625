import React, { Component } from 'react'

import Rodape from './Rodape'
import Cabecalho from './Cabecalho'
import Inicio from './Inicio'
import Servicos from './Servicos'
import Portfolio from './Portfolio'
import Contato from './Contato'
import Solar from './Solar'
import Parceiro from './Parceiro'

class PaginaInicio extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {

    return (

      <div>
        <Cabecalho />
        <Solar />
        <Inicio />
        <Servicos />
        <Parceiro />
        <Contato />
        <Portfolio />
        <Rodape />
      </div>
    );
  }

}

export default PaginaInicio;
