import React from 'react';
import './estilos.scss';

const Rodape = () => {
    return (
        <footer className='rodape'>
            <div className='wrap'>
            Copyright © 2020 Magalhães Engenharia Elétrica. Todos os direitos reservados. Desenvoldido por <a href='https://www.issidios.com' title='Visite Issidios Tecnologia'>Issidios Tecnologia</a>
            </div>

        </footer>
    );
};

export default Rodape;