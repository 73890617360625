import React from 'react';
import Rodape from '../componentes/Footer';
import Header from '../componentes/Header/Header';

const LayoutPaginaPrincipal = props => {
    return (
        <div className='fullHeigth'>
            <Header {...props} />
            {props.children}
            <Rodape/>
        </div>
    );
};

export default LayoutPaginaPrincipal;