import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import PaginaInicio from './componentes/PaginaInicio'
//Area administrativa
import Admin from './admin/Admin'
import LoginAdmin from './admin/LoginAdmin'
import Detalhes from './detalhes/Detalhes'
import Orcamento from './orcamento/Orcamento'
import Loja from './loja/Loja'
import { Provider } from 'react-redux';
import store from './store'
import WithAdminAuth from './hoc/withAdminAuth'

class App extends Component {
  render() {

    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="App">
            <Route path='/' exact component={PaginaInicio} />
            <Route path='/admin' render={() => (
              <WithAdminAuth>
                <Admin />
              </WithAdminAuth>
            )} />
            <Route path='/login' component={LoginAdmin} />
            <Route path='/detalhes' component={Detalhes} />
            <Route path='/orcamento' component={Orcamento} />
            <Route path='/loja' component={Loja} />
          </div>
        </BrowserRouter>
      </Provider>
    );
  }

}

export default App;
