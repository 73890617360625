import usuariosTypes from './usuarios.types'
import { takeLatest, call, all, put } from 'redux-saga/effects'
import { signInSuccess, signOutUserSuccess, reserPassawordSuccess, userError } from './usuarios.actions'
import { auth, GoogleProvider, getCurrentuser, handleUserProfile } from '../../../fbConfig';
import { handleResetpassoedAPI } from './usuarios.helpers';

export function* getSnapshotFromUserAuth(user, additionalData = {}) {
    try {
        const userRef = yield call(handleUserProfile, { userAuth: user, additionalData });
        const snapshot = yield userRef.get();
        yield put(
            signInSuccess({
                id: snapshot.id,
                ...snapshot.data()
            })
        )
    } catch (error) {

    }
}

export function* emailSignIn({ payload: { email, senha } }) {
    try {
        const { user } = yield auth.signInWithEmailAndPassword(email, senha);
        yield getSnapshotFromUserAuth(user);


    } catch (error) {

    }
}

export function* onEmailSignInStart() {
    yield takeLatest(usuariosTypes.EMAIL_SIGN_IN_START, emailSignIn)
}

export function* isUserAuthenticated() {
    try {
        const userAuth = yield getCurrentuser();
        if (!userAuth) return;
        yield getSnapshotFromUserAuth(userAuth);
    } catch (error) {

    }
}

export function* onCheckUserSession() {
    yield takeLatest(usuariosTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutUser() {
    try {
        yield auth.signOut();
        yield put(
            signOutUserSuccess()
        )
    } catch (error) {

    }
}

export function* onSignOutUserStart() {
    yield takeLatest(usuariosTypes.SIGN_OUT_USER_START, onSignOutUser)
}

export function* signUpUser({ payload: {
    displayName,
    email,
    senha,
    confirmaSenha
} }) {
    if (senha !== confirmaSenha) {

        const err = ['Senhas não combinam'];
        yield put(
            userError(err)
        );
        return;
    }

    try {
        const { user } = yield auth.createUserWithEmailAndPassword(email, senha);
        const additionalData = { displayName };
        yield getSnapshotFromUserAuth(user, additionalData)
    } catch (error) {

    }
}

export function* onSignUpUserStart() {
    yield takeLatest(usuariosTypes.SIGN_UP_USER_START, signUpUser);
}

export function* resetPassword({ payload: { email } }) {

    try {
        yield call(handleResetpassoedAPI, email);
        yield put(
            reserPassawordSuccess()
        );
    } catch (error) {
        yield put(
            userError(error)
        );
    }
}

export function* onResetPasswordStart() {
    yield takeLatest(usuariosTypes.RESET_PASSWORD_START, resetPassword)
}

export function* googleSignIn() {
    try {
        const { user } = yield auth.signInWithPopup(GoogleProvider)
        yield getSnapshotFromUserAuth(user);
    } catch (error) {

    }
}

export function* onGoogleSignInStart() {
    yield takeLatest(usuariosTypes.GOOGLE_SIGN_IN_START, googleSignIn)
}

export default function* userSagas() {
    yield all([
        call(onEmailSignInStart),
        call(onCheckUserSession),
        call(onSignOutUserStart),
        call(onSignUpUserStart),
        call(onResetPasswordStart),
        call(onGoogleSignInStart),
    ])
}