import React from 'react';
import Rodape from '../componentes/Footer';
import Header from '../componentes/Header/Header';

const LayoutPrincipal = props => {
    return (
        <div>
            <Header {...props}/>
            <div className='main'>
                {props.children}
            </div>
            <Rodape/>
        </div>
    );
};

export default LayoutPrincipal;