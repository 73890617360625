import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import FirebaseService from '../../../servicos/FirebaseServicos';

export const privateUrlsConquista = {
    edit: {
        name: 'Edit',
        path: '/admin/conquistas-editar/:id',
        pathWithouParam: '/admin/conquistas-editar/'
    }
};

class AdminEditarCadastroConquistas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: null,
            titulo: '',
            valor: '',
        };

        this.gravaPortfolio = this.gravaPortfolio.bind(this)

        this.UNSAFE_componentWillMount = () => {
            const { id } = this.props.match.params;
            if (!(id === undefined || !id)) {
                this.setState({ id });
                FirebaseService.getUniqueDataBy('conquistas', id, (data) => this.setState({ titulo: data[0], valor: data[1] }));
            }
        };
    }




    gravaPortfolio(e) {
        var itemPortfolio = {
            titulo: this.state.titulo,
            valor: this.valor.value,
        }
        try {
            console.log(itemPortfolio)
            FirebaseService.updateData(this.props.match.params.id, 'conquistas', itemPortfolio)
            this.setState({ estaGravando: false, gravado: true })
            alert("Registro alterado com sucesso!")
        } catch (error) {
            alert("Erro ao alterar o registro: " + error)
        }




    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    render() {
        if (this.state.estaGravando) {
            return (
                <div className='container'>
                    <p><span className='glyphicon glyphicon-refresh'> Aguarde...</span></p>
                </div>
            )
        }
        if (this.state.gravado) {
            return <Redirect to='/admin/conquistas' />
        }
        return (
            <div>
                <div className='bg-grey'>
                    <div className='container '>
                        <h2>Editar Conquista - {this.state.titulo}</h2>
                        <form onSubmit={this.gravaPortfolio}>
                            <div className="form-group">
                                <label htmlFor="titulo">Valor</label>
                                <input type="text" className="form-control" defaultValue={this.state.valor} placeholder="Titulo" ref={(ref) => this.valor = ref} required />
                            </div>
                            <button type="submit" className="btn btn-primary">Salvar</button>
                            <Link to='/admin/conquistas'>
                                <button className="btn btn-primary btn-cancelar">Cancelar</button>
                            </Link>
                        </form>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}

export default AdminEditarCadastroConquistas;