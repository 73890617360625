import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo_v.png'
import './estilo.scss';
import { signOutUserStart } from '../../../store/reducers/Usuario/usuarios.actions';

const mapState = ({ user }) => ({
    currentUser: user.currentUser
})

const Header = props => {
    const dispatch = useDispatch();
    const { currentUser } = useSelector(mapState);

    const signOut = () => {
        dispatch(signOutUserStart());
    }

    return (
        <header className='header'>
            <div className='wrap'>
                <div className='logo'>
                    <Link to='/loja'>
                        <img src={Logo} alt='Magalhães Logo' />
                    </Link>
                </div>

                {/* <div className='chamarAcoes'>

                    {currentUser && (
                        <ul>
                            <li>
                                <Link to='/loja/dashboard'>
                                    Minha Conta
                            </Link>
                            </li>
                            <li>
                                <span onClick={() => signOut()}>
                                    Logout
                                </span>
                            </li>
                        </ul>
                    )}

                    {!currentUser && (
                        <ul>
                            <li>
                                <Link to='/loja/registro'>
                                    Registro
                            </Link>
                            </li>
                            <li>
                                <Link to='/loja/login'>
                                    Login
                            </Link>
                            </li>
                        </ul>
                    )}

                </div> */}
            </div>
        </header>
    );
};

Header.defaultProps = {
    currentUser: null
}

export default Header;