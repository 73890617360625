import React from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        sidebar: state.Detalhes.sidebar
    };
}

const ComoFunciona = ({sidebar}) => {
    
    const isOn = sidebar
        return (
            <div>
                <div id='' className={isOn ? 'container-fluid bg-grey page-content p-5 content' : 'container-fluid bg-grey page-content p-5 content active'}>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <h2>Como Funciona?</h2><br />
                            <h3><b>Como é que a luz do sol se transforma em energia para alimentar o consumo dos seus aparelhos elétricos?</b></h3><br />
                            <div className='comoFuncionaDetalhes'>
                                <li>Os <b>módulos fotovoltaicos</b> que são instalados no telhado do imóvel ou em uma estrutura metálica no solo, reagem com a luz do sol
                e produzem energia elétrica (fotovoltaica) em corrente contínua.</li>
                                <li>A energia produzida pelos módulos solares é convertida em corrente alternada pelo <b>inversor solar</b>, tornando possível a sua utilização pelos aparelhos elétricos que você deseja ligar; </li>
                                <li>Ao chegar no "quadro de luz" <b>a energia solar gerada é distribuída em todo o imóvel</b>, diminuindo, assim, o consumo da energia que você compra da concessionária;</li>
                                <li>Com o <b>medidor bidirecional</b> você registra a quantidade de energia injetada e a consumida na rede; </li>
                                <li>A <b>energia injetada</b> na rede da concessionária se transforma em créditos que podem ser utilizados para reduzir a sua conta de energia elétrica no local de geração ou em outra conta de mesma titularidade, esses créditos têm validade de 60 meses.</li>
                            </div>
                            <br />
                            <img src="./imagens/detalhes/simulador-energia-solar-fotovoltaica-eletron-energia.jpg" alt="Item 1" className='imagemDetalhes' />
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default connect(
    mapStateToProps,
)(ComoFunciona);