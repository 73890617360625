import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { checkuserSession } from '../store/reducers/Usuario/usuarios.actions';
import { useDispatch } from 'react-redux';

//hoc



// layouts
import LayoutPrincipal from './layouts/LayoutPrincipal';
import LayoutPaginaPrincipal from './layouts/LayoutPaginaPrincipal';
//Paginas
import Registro from './paginas/Registro';
import Principal from './paginas/Principal';
import Login from './paginas/Login';

import RecuperarSenha from './paginas/RecuperarSenha';
import Dashboard from './paginas/Dashboard';
import WithAuth from '../hoc/withAuth';

const Loja = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkuserSession);
        window.scrollTo(0, 0);

    }, []);

    return (
        <div>
            <Switch>
                <Route path='/loja' exact render={() => (
                    <LayoutPaginaPrincipal>
                        <Principal />
                    </LayoutPaginaPrincipal>
                )} />
                {/* <Route path='/loja/registro' render={() => (
                    <LayoutPrincipal>
                        <Registro />
                    </LayoutPrincipal>
                )} />
                <Route path='/loja/login'
                    render={() => (
                        <LayoutPrincipal>
                            <Login />
                        </LayoutPrincipal>
                    )} /> */}
                <Route path='/loja/recuperar-senha' render={() => (
                    <LayoutPrincipal>
                        <RecuperarSenha />
                    </LayoutPrincipal>
                )} />
                <Route path='/loja/dashboard' render={() => (
                    <WithAuth>
                        <LayoutPrincipal>
                            <Dashboard />
                        </LayoutPrincipal>
                    </WithAuth>
                )} />
            </Switch>
        </div>
    );
}


export default Loja;