import { auth } from "../../../fbConfig"

export const handleResetpassoedAPI = (email) => {
    const config = {
        url: 'http://localhost:3000/loja/login'
    }
    return new Promise((resolve, reject) => {
        auth.sendPasswordResetEmail(email, config)
            .then(() => {
                resolve();
            })
            .catch(() => {
                const err = ['Email não encontrado. Por favor, tente novamente.'];
                reject(err);
            })
    })
}