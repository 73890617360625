import React from 'react';

const Principal = props => {
    return (
        <div>
            <h2>Página em Manutenção</h2>
        </div>
    );
};

export default Principal;