import React, { Component } from 'react'

import CabecalhoDetalhes from './CabecalhoDetalhes';
import EnergiaSolar from './EnergiaSolar'
import ComoFunciona from './ComoFunciona'
import Manutencao from './Manutencao'
import Monitoramento from './Monitoramento'
import Legislacao from './Legislacao'
import Seguro from './Seguro'
import Retorno from './Retorno';


class Detalhes extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {

        return (

            <div >
                <CabecalhoDetalhes />
                <EnergiaSolar />
                <ComoFunciona />
                <Manutencao />
                <Monitoramento />
                <Legislacao />
                <Retorno />
                <Seguro />
            </div>
        );
    }

}

export default Detalhes;
