const Rebase = require('re-base')
const firebase = require('firebase')

const config = {
  apiKey: "AIzaSyBpyHo8D4e4cexDJX10qxIKDCrsdWp1FBA",
  authDomain: "magalhaesbd-c856e.firebaseapp.com",
  databaseURL: "https://magalhaesbd-c856e.firebaseio.com",
  projectId: "magalhaesbd-c856e",
  storageBucket: "magalhaesbd-c856e.appspot.com",
  messagingSenderId: "717979424583",
  appId: "1:717979424583:web:be20a8ef6480f453de9e62",
  measurementId: "G-B4DMN4NT2N"
}

const app = firebase.initializeApp(config)
const fbConfig = Rebase.createClass(app.database())
export const firestore = app.firestore();

export const firebaseDatabase = app.database();

export const storage = app.storage()
export const auth = app.auth()

export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({ prompt: 'select_account' });


export const handleUserProfile = async ({ userAuth, additionalData }) => {
  if (!userAuth) return;
  const { uid } = userAuth;
  const userRef = firestore.doc(`usuarios/${uid}`);
  const snapshot = await userRef.get();
  const userRolar =['users']

  if (!snapshot.exists) {

    const { displayName, email } = userAuth;
    const timestamp = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdDate: timestamp,
        userRolar,
        ...additionalData
      })
    } catch (err) {

    }
  }
  return userRef;
}

export const getCurrentuser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  })
}

export default fbConfig