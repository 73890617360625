import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emailSignInStart, googleSignInStart } from '../../../store/reducers/Usuario/usuarios.actions'
import './estilos.scss';
import Botoes from '../forms/Botao'
import FormInput from '../forms/FormInput';
import AuthWrapper from '../AuthWrapper';
import { Link, useHistory, withRouter } from 'react-router-dom';

const mapState = ({ user }) => ({
    currentUser: user.currentUser
})

const EntrarLogin = props => {
    const { currentUser } = useSelector(mapState);
    const dispatch = useDispatch();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    useEffect(() => {
        if (currentUser) {
            resetForm();
            history.push('/loja');
        }
    }, [currentUser])

    const resetForm = () => {
        setEmail('');
        setSenha('');
    }

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(emailSignInStart({ email, senha }));
    }

    const handleGoogleLogin = () => {
        dispatch(googleSignInStart());
    }

    const configAuthWrapper = {
        headline: 'Login'
    }

    return (
        <AuthWrapper {...configAuthWrapper}>

            <div className='formWrap'>
                <form onSubmit={handleSubmit}>
                    <FormInput
                        type='email'
                        name='email'
                        value={email}
                        placeholder='E-mail'
                        handleChange={e => setEmail(e.target.value)}
                        required />

                    <FormInput
                        type='password'
                        name='senha' value={senha}
                        placeholder='Senha'
                        handleChange={e => setSenha(e.target.value)}
                        required />

                    <Botoes type='submit'>
                        Login
                            </Botoes>

                    <div className='loginSocial'>
                        <div className='row'>
                            <Botoes onClick={handleGoogleLogin}>
                                Login com Google
                            </Botoes>
                        </div>
                    </div>
                    <div className='link'>
                        <Link to='/loja/recuperar-senha'>
                            Esqueci a senha
                            </Link>
                    </div>
                </form>
            </div>
        </AuthWrapper >

    );
};

export default withRouter(EntrarLogin);