import usuariosTypes from './usuarios.types';

const INITICAL_STATE = {
    currentUser: null,
    resetPasswordSuccess: false,
    userErr: []
};

const usuariosReducer = (state = INITICAL_STATE, action) => {
    switch (action.type) {
        case usuariosTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                userErr: []
            }
        case usuariosTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordSuccess: action.payload
            }
        case usuariosTypes.USER_ERROR:
            return {
                ...state,
                userErr: action.payload
            }
        case usuariosTypes.RESET_USER_STATE:
        case usuariosTypes.SIGN_OUT_USER_SUCCESS:
            return {
                ...state,
                ...INITICAL_STATE
            }
        default:
            return state;
    }
};

export default usuariosReducer;