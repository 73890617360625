
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthWrapper from '../AuthWrapper';
import Botoes from '../forms/Botao';
import FormInput from '../forms/FormInput';
import './estilos.scss';
import { useHistory, withRouter } from 'react-router-dom';
import { resetPassawordStart, resetUserState} from '../../../store/reducers/Usuario/usuarios.actions';

const mapState = ({ user }) => ({
    resetPasswordSuccess: user.resetPasswordSuccess,
    userErr: user.userErr
});

const EmailSenha = props => {
    const dispatch = useDispatch();
    const { resetPasswordSuccess, userErr } = useSelector(mapState);
    const history = useHistory();
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState([])

    const resetForm = () => {
        setEmail('');
        setErrors([]);
    }

    useEffect(() => {
        if (resetPasswordSuccess) {
            dispatch(resetUserState());
            history.push('/loja/login');
        }
    }, [resetPasswordSuccess]);

    useEffect(() => {
        if (Array.isArray(userErr) && userErr.length > 0) {
            setErrors(userErr)
        }
    }, [userErr]);

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(resetPassawordStart({ email }));
    }

    const configAuthWrapper = {
        headline: 'Esqueci a senha'
    }

    return (
        <AuthWrapper {...configAuthWrapper}>
            <div className="formWrap">

                {errors.length > 0 && (
                    <ul>
                        {errors.map((e, index) => {
                            return (
                                <li key={index}>
                                    {e}
                                </li>
                            );
                        })}
                    </ul>
                )}

                <form onSubmit={handleSubmit}>

                    <FormInput
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        handleChange={e => setEmail(e.target.value)}
                        required
                    />

                    <Botoes type="submit">
                        Enviar
          </Botoes>

                </form>

            </div>
        </AuthWrapper>
    );
}

export default withRouter(EmailSenha);