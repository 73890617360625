import React, { Component } from 'react';
import RegistroLogin from '../../componentes/RegistroLogin';
import './estilos.scss';

class Registro extends Component {
    render() {
        return (
            <RegistroLogin />
        );
    }
}

export default Registro;