import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { checkUserIsadmin } from '../Util'

const mapState = ({ user }) => ({
    currentUser: user.currentUser
})

const useAdminAuth = props => {
    const { currentUser } = useSelector(mapState)
    const history = useHistory();

    useEffect(() => {
        if (!checkUserIsadmin(currentUser)) {
            history.push('/login')
        }
    }, [currentUser]);

    return currentUser;
}

export default useAdminAuth;