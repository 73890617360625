import usuariosTypes from './usuarios.types'

export const emailSignInStart = userCredenciais => ({
    type: usuariosTypes.EMAIL_SIGN_IN_START,
    payload: userCredenciais
})

export const signInSuccess = user => ({
    type: usuariosTypes.SIGN_IN_SUCCESS,
    payload: user
})

export const checkuserSession = () => ({
    type: usuariosTypes.CHECK_USER_SESSION

});

export const signOutUserStart = () => ({
    type: usuariosTypes.SIGN_OUT_USER_START
})

export const signOutUserSuccess = () => ({
    type: usuariosTypes.SIGN_OUT_USER_SUCCESS
})

export const signUpUserStart = userCredenciais => ({
    type: usuariosTypes.SIGN_UP_USER_START,
    payload: userCredenciais
})

export const userError = err => ({
    type: usuariosTypes.USER_ERROR,
    payload: err
})

export const resetPassawordStart = userCredenciais => ({
    type: usuariosTypes.RESET_PASSWORD_START,
    payload: userCredenciais
})

export const reserPassawordSuccess = () => ({
    type: usuariosTypes.RESET_PASSWORD_SUCCESS,
    payload: true
})

export const resetUserState = ()=> ({
    type: usuariosTypes.RESET_USER_STATE
})

export const googleSignInStart = () => ({
    type: usuariosTypes.GOOGLE_SIGN_IN_START
})