import React from 'react';
import './estilos.scss'

const Dashboard = props => {
    return (
        <h2>
            Você está logado!
        </h2>
    );
};

export default Dashboard;