import React, { Component} from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import { Link } from 'react-router-dom'
import FormularioContato from './FormularioContato'


class Contato extends Component {

    render() {


        return (

            /* Container (Contact Section) */
            <div id='contact' className='container-fluid bg-grey'>
                <h2 className='text-center'>Fale Conosco</h2>
                <h3 className='text-center'>Responderemos o mais rapido possivel</h3><br />
                <div className='row'>
                    
                    <div className='col-sm-6 textoContato'>
                        <p>Para atendê-lo melhor, disponibilizamos esse canal para informações e atendimento.</p>
                        <p>Mas se você está interessando em um <b>orçamento</b> para instalar um de nossos sistemas de energia solar, temos um espaço dedicado para sua solicitação.</p>
                        <Link className="btn btn-default btnContato" to='/orcamento'>
                            Requisitar orçamento
                    <i className="glyphicon glyphicon-chevron-right logo-small" />
                        </Link>
                        <br />
                        <p>Você também pode nos achar em nossas redes sociais.</p>
                        <div className='text-center separadorContato '></div>
                        <div className='text-center midia'>
                            <a href="https://www.facebook.com/engenhariamagalhaes" title="https://www.facebook.com/engenhariamagalhaes">
                                <img src='./imagens/logo/Facebook.png' alt='' />
                            </a>
                            <a href="https://www.instagram.com/engenhariamagalhaes/?hl=pt-br" title="https://www.instagram.com/engenhariamagalhaes/?hl=pt-br">
                                <img src='./imagens/logo/Instagram.png' alt='' />
                            </a>
                        </div>
                    </div>
                    <FormularioContato />
                </div>
                <br />
                <h2 className='text-center'>Venha nos visitar</h2>
                <div >
                    <Tabs>

                        <TabList>
                            <Tab>Pontes e Lacerda</Tab>
                            <Tab>Várzea Grande</Tab>
                        </TabList>

                        <TabPanel>
                            <p ><span className='glyphicon glyphicon-map-marker'></span>BR 174B, Nº 3226 - Boa Vista, Pontes e Lacerda - MT</p>
                            <p ><span className='glyphicon glyphicon-phone'></span>(65) 99345-2432</p>
                            <p ><span className='glyphicon glyphicon-envelope'></span> vendas@magalhaesengenharia.com</p>
                            <p ><span className='glyphicon glyphicon-time'></span> Segunda à Quinta - 07:00 - 18:00 / Sexta - 07:00 - 17:00</p>
                            <Map center={[-15.242102520511345, -59.33365530000001]} zoom={15}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                />
                                <Marker position={[-15.242102520511345, -59.33365530000001]}>
                                    <Popup>Magalhães Engenharia Eletrica<br />
                                        <a href="https://www.google.com/maps/search/-15.227965,+-59.335171?sa=X&ved=2ahUKEwjC7-LBxJ3sAhUpIbkGHcmOCxMQ8gEwAHoECAEQAQ" >
                                            Venha nos visitar.</a></Popup>
                                </Marker>
                            </Map>


                        </TabPanel>
                        <TabPanel>
                            <p ><span className='glyphicon glyphicon-map-marker'></span>R. São Bernardo, 95 - Centro Norte, Várzea Grande - MT</p>
                            <p ><span className='glyphicon glyphicon-phone'></span>(65) 99345-2432</p>
                            <p ><span className='glyphicon glyphicon-envelope'></span> vendas@magalhaesengenharia.com</p>
                            <p ><span className='glyphicon glyphicon-time'></span> Segunda à Quinta - 07:00 - 18:00 / Sexta - 07:00 - 17:00</p>
                            <Map center={[-15.641750183225042, -56.12448153909952]} zoom={15}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                />
                                <Marker position={[-15.641750183225042, -56.12448153909952]}>
                                    <Popup>Magalhães Engenharia Eletrica<br />
                                        <a href="https://www.google.com/maps/search/-12.7411408,+-60.1365177?sa=X&ved=2ahUKEwi65PLMxJ3sAhU4H7kGHVPQA-cQ8gEwAHoECAEQAQ" >
                                            Venha nos visitar.</a></Popup>
                                </Marker>
                            </Map>
                        </TabPanel>
                    </Tabs>
                </div>
                <br />

            </div>
        )
    }
}

export default Contato