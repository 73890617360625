import React from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        sidebar: state.Detalhes.sidebar
    };
}

const Monitoramento = ({sidebar}) => {
    
    const isOn = sidebar
        return (
            <div>
                <div id='monitoramento' className={isOn ? 'container-fluid bg-grey page-content p-5 content' : 'container-fluid bg-grey page-content p-5 content active'}>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <h2>Monitoramento da produção de energia solar</h2><br />
                            <h3><b>A melhor forma de verificar se o sistema de energia solar está funcionando como deveria é monitorar a produção de energia. Uma queda anormal no desempenho pode ser um indicativo de que a manutenção é necessária.</b></h3><br />
                            <div className='comoFuncionaDetalhes'>
                                <p>Esse controle pode ser realizado através de um aplicativo no celular ou computador, no qual é possível verificar a efetividade da produção de energia solar, fazer o monitoramento do desempenho em tempo real, saber quanto de energia está sendo produzida, entre outros parâmetros. Com isso, pode-se verificar o funcionamento do sistema ou possíveis falhas a serem corrigidas.</p>
                                <p><b>A solução oferece:</b></p>
                                <li>Acesso remoto aos dados de produção de energia solar por dispositivos conectados à internet;</li>
                                <li>Envio de dados por conexão WiFi;</li>
                                <li>Envio de alertas em tempo real em caso de falha;</li>
                                <li>Elaboração de relatórios de dados enviados por e-mail;</li>
                                <li>Interface de fácil entendimento.</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default connect(
    mapStateToProps,
)(Monitoramento);